import * as CommandTypes from './command-types'

export const AB: CommandTypes.AB = 10
export const FOR: CommandTypes.For = 20
export const FILL: CommandTypes.Fill = 30
export const SPLIT: CommandTypes.Split = 40
export const CROOK: CommandTypes.Crook = 50
export const LINES: CommandTypes.SamePlace = 60
export const SHIFT: CommandTypes.Shift = 70
export const MARGIN: CommandTypes.Margin = 80
export const STROKE: CommandTypes.Stroke = 90
export const SQUARE: CommandTypes.Square = 100
export const WINDOW: CommandTypes.Window = 110
export const SWITCH: CommandTypes.Switch = 120
export const CIRCLE: CommandTypes.Circle = 130
export const TRIANGLE: CommandTypes.Triangle = 140
export const MULTIPLY: CommandTypes.Multiply = 150
export const SAME_PLACE: CommandTypes.SamePlace = 160
export const DOTTED_STROKE: CommandTypes.DottedLine = 170
export const TERMINATE_WHEN: CommandTypes.TerminateWhen = 180
export const INCREMENT_REGISTER_1: CommandTypes.Increment1 = 190
export const DECREMENT_REGISTER_1: CommandTypes.Decrement1 = 200
export const INCREMENT_REGISTER_2: CommandTypes.Increment2 = 210
export const DECREMENT_REGISTER_2: CommandTypes.Decrement2 = 220
export const INCREMENT_REGISTER_3: CommandTypes.Increment3 = 230
export const DECREMENT_REGISTER_3: CommandTypes.Decrement3 = 240
export const TERMINATE: CommandTypes.Terminate = 250
