import React, { Component } from 'react'
import { sample, random } from 'lodash'
import Parser from '../../parser/Parser'
import {
  Viewport,
  sleep,
  measureDuration,
  NOOP,
  isTouchDevice,
} from '../../utils'
import { DELAY, EMPTY_WORK_COMPLEXITY } from '../../const'
import { appMode, isInteractive } from '../../settings'
import { GridSizeUnits } from '../../graphics/gridSize'
import { fitnessFunction } from '../../graphics/fitness' // findSuitableRandomDrawing
import Network from '../../cgp/Network'
import ImageFlipBuffer from '../../graphics/ImageFlipBuffer'
import MutateToolbarMobile from '../toolbars/MutateToolbarMobile'
import MutateToolbar from '../toolbars/MutateToolbar'

interface Props {
  visible: boolean
  drawing: Network
  onClickRemember: (grid: Network, index: number) => void
  onClickEdit: (drawing: Network) => void
  gridSizeUnits: GridSizeUnits
  drawingSize: Viewport
}

const Toolbar = isTouchDevice ? MutateToolbarMobile : MutateToolbar

export default class MutatorScreen extends Component<Props> {
  private readonly drawings: Network[] = []
  private variationsBuffers: ImageFlipBuffer[] = []
  private breedCounter = 0
  private firstTime = true
  private lastChosen = 0
  private busyAutobreeding = false
  private previousLargestComplexity = 0
  private containerElement: HTMLDivElement | null = null
  private fpsElement: HTMLDivElement | undefined

  mutate = async (index: number): Promise<void> => {
    if (this.busyAutobreeding) return
    this.busyAutobreeding = true
    Toolbar.allEnabled = false
    await this.choose(index)
  }

  onClickEdit = (drawing: Network): void => {
    this.props.onClickEdit(drawing)
  }

  createFlipBuffer(index: number): ImageFlipBuffer {
    if (this.containerElement === null) {
      throw new Error('Parent container not initialised')
    }
    const { drawingSize, gridSizeUnits } = this.props

    const imageFlipBuffer = ImageFlipBuffer.factory(
      this.containerElement,
      index,
      drawingSize,
      gridSizeUnits,
    )

    if (isInteractive) {
      const mutateToolbar = new Toolbar(imageFlipBuffer.element)

      mutateToolbar.onClickRemember = () =>
        this.props.onClickRemember(this.drawings[index], index)

      mutateToolbar.onClickMutate = () => this.mutate(index)
      mutateToolbar.onClickEdit = () => this.onClickEdit(this.drawings[index])
      if (!isTouchDevice) {
        imageFlipBuffer.onClick = () => this.mutate(index)

        imageFlipBuffer.element.addEventListener('mouseover', () => {
          mutateToolbar.visible = true
        })

        imageFlipBuffer.element.addEventListener('mouseout', () => {
          mutateToolbar.visible = false
        })
      }
    }

    return imageFlipBuffer
  }

  removeAllBuffers(): void {
    for (let index = 0; index < this.variationsBuffers.length; index++) {
      this.variationsBuffers[index].remove()
    }

    this.variationsBuffers = []
  }

  updateStatus(): void {
    if (this.fpsElement) {
      const { layerSize, layers, colors, memory } = this.drawings[
        this.lastChosen
      ].dimensions!
      this.fpsElement.innerHTML = `1.6 - ${layerSize}x${layers} - ${colors} colors - ${memory} mem.`
    }
  }

  async drawNewPopulation(refresh = false): Promise<void> {
    const drawing = new Network(refresh ? undefined : this.props.drawing)
    this.updateStatus()
    this.lastChosen = 0
    this.drawings[this.lastChosen] = drawing
    this.firstTime = true
    this.breedCounter = random(24) + 16

    this.mutateChildren()
    await this.draw()
  }

  randomDifferentIndex(): number {
    const { count } = this.props.gridSizeUnits
    const bag: number[] = [...Array(count).keys()].filter(
      (index) => index !== this.lastChosen,
    )
    return sample(bag) as number
  }

  async choose(index: number): Promise<void> {
    this.updateCursor()
    await this.variationsBuffers[index].highlight(index === this.lastChosen)
    await sleep(DELAY * 8)
    this.lastChosen = index
    this.updateStatus()
    this.mutateChildren()
    await this.draw()
  }

  mutateChildren(): void {
    const { count } = this.props.gridSizeUnits
    for (let i = 0; i < count; i++) {
      if (i === this.lastChosen) continue
      const child = new Network(this.drawings[this.lastChosen])
      child.mutate(random(3) + 1)
      this.drawings[i] = child
    }
  }

  async drawBuffer(buffer: ImageFlipBuffer, drawing: Network): Promise<void> {
    await buffer.highlight()
    buffer.start()
    const parser = new Parser(drawing, buffer.svg, this.props.drawingSize)

    await sleep(DELAY * 2)
    await parser.draw()

    buffer.flip()
    await sleep(DELAY * 3)
    buffer.unhighlight()
  }

  async draw(): Promise<void> {
    const { count } = this.props.gridSizeUnits
    this.busyAutobreeding = true
    Toolbar.allEnabled = false
    this.updateCursor()
    let tooSlow = false
    this.updateStatus()

    for (let i = 0; i < count; i++) {
      // fixme: als 1 te lang duurt niet de andere tekenen
      if (i === this.lastChosen && !this.firstTime) continue
      const { duration } = await measureDuration(async () => {
        if (tooSlow) return
        return await this.drawBuffer(
          this.variationsBuffers[i],
          this.drawings[i],
        )
      })

      if (duration > 2000) {
        console.log('too slow')
        tooSlow = true // eslint-disable-line
      }
    }

    this.firstTime = false
    if (appMode === 'autobreeder') {
      await sleep(DELAY * 16)
      this.breedCounter--
      console.log(this.breedCounter)
    } else {
      await sleep(DELAY * 4)
      this.unhighlightLastChosen()
    }

    this.busyAutobreeding = false
    Toolbar.allEnabled = true
    this.updateCursor()

    if (appMode === 'autobreeder') await this.judge(tooSlow)
  }

  async judge(tooSlow: boolean): Promise<void> {
    if (this.breedCounter <= 0 || tooSlow) {
      // await this.showLarge() // this.lastChosen)
      this.unhighlightLastChosen()
      return await this.restart()
    }
    const f = await fitnessFunction(this.variationsBuffers)
    this.unhighlightLastChosen()

    if (this.previousLargestComplexity === f.largestComplexity) {
      await this.showLarge() // this.lastChosen)
      return await this.restart()
    } else {
      this.previousLargestComplexity = f.largestComplexity
    }

    if (f.largestComplexity < EMPTY_WORK_COMPLEXITY) {
      return await this.restart()
    }

    return await this.choose(f.largestIndex)
  }

  async showLarge(): Promise<void> {
    // index: number
    // eslint-disable-line
    // if (this.containerElement === null) throw new Error('should not happen')
    // const v: Viewport = viewport()
    // const smallest = Math.min(v.width, v.height)
    // const size = { width: smallest, height: smallest }
    // const buffer = new ImageFlipBuffer(this.containerElement, size)
    // buffer.x = (v.width - smallest) / 2
    // buffer.y = (v.height - smallest) / 2
    // buffer.start()
    // const parser = new Parser(this.drawings[index], buffer.svg, size)
    // await parser.draw()
    // buffer.flip()
    // await sleep(4000)
    // await ImageFlipBuffer.fadeOutAll()
    // buffer.svgInstances.forEach(instance => {
    //   if (this.containerElement === null) throw new Error('should not happen')
    //   this.containerElement.removeChild(instance.node)
    // })
  }

  unhighlightLastChosen(): void {
    this.variationsBuffers[this.lastChosen].unhighlight()
  }

  async restart(): Promise<void> {
    this.previousLargestComplexity = 0
    await ImageFlipBuffer.fadeOutAll()
    await this.drawNewPopulation(true)
  }

  updateCursor(): void {
    if (this.containerElement === null) return
    this.containerElement.style.cursor = isInteractive
      ? this.busyAutobreeding
        ? 'wait'
        : 'pointer'
      : 'none'
  }

  initMutatorScreen = (containerElement: HTMLDivElement): void => {
    if (containerElement === undefined || this.containerElement !== null) return
    const { count } = this.props.gridSizeUnits
    this.containerElement = containerElement
    for (let index = 0; index < count; index++) {
      this.variationsBuffers[index] = this.createFlipBuffer(index)
    }

    this.drawNewPopulation().then(NOOP, NOOP)
  }

  componentWillUnmount(): void {
    this.removeAllBuffers()
  }

  initFpsElement = (fpsElement: HTMLDivElement): void => {
    if (fpsElement) {
      this.fpsElement = fpsElement
    }
  }

  render(): JSX.Element {
    this.updateCursor()
    return (
      <>
        <div
          id='drawings'
          style={{ display: this.props.visible ? 'block' : 'none' }}
          ref={this.initMutatorScreen}
        />
        <div className='fps' ref={this.initFpsElement}>
          ?
        </div>
      </>
    )
  }
}
