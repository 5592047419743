import { viewport } from '../utils'
import { BORDER_WIDTH } from '../const'
import { isInteractive } from '../settings'

export const TOP_MARGE = isInteractive ? 48 : 0

export interface GridSizeUnits {
  columns: number
  rows: number
  count: number
}
interface ColumnsRow {
  columns: number
  rows: number
}

const MAX_COLS = 4
const MAX_ROWS = 4

const withCount = (dims: ColumnsRow): GridSizeUnits => ({
  ...dims,
  count: dims.columns * dims.rows,
})

export function calculateGridSizeUnits(): GridSizeUnits {
  if (location.hash !== '') {
    const dim = location.hash.slice(1).split('x')
    if (dim.length === 2) {
      return withCount({
        columns: parseInt(dim[0]),
        rows: parseInt(dim[1]),
      })
    }
  }
  const screen = viewport()
  const v = {
    width: screen.width,
    height: screen.height - TOP_MARGE,
  }
  const size = 280
  const columns = Math.round(v.width / size + 0.2)
  const rows = Math.round(v.height / size + 0.2)
  const ratio = v.width / v.height

  if (columns === 1 && rows === 2) {
    return withCount({
      columns: 1,
      rows: 3,
    })
  }

  if (columns === 2 && rows === 1) {
    return withCount({
      columns: 3,
      rows: 1,
    })
  }

  if (columns + rows === 2) {
    if (ratio > 0.9 && ratio < 1.25) {
      return withCount({
        columns: 2,
        rows: 2,
      })
    }
    return withCount({
      columns: 1,
      rows: 3,
    })
  }

  if (columns >= MAX_COLS) {
    return withCount({
      columns: MAX_COLS,
      rows: Math.round(MAX_COLS / ratio + 0.2),
    })
  }

  if (rows >= MAX_ROWS) {
    return withCount({
      columns: Math.round(MAX_ROWS * ratio + 0.2),
      rows: MAX_ROWS,
    })
  }

  return withCount({
    columns,
    rows,
  })
}

export function calculateDrawingsize(
  gridSizeUnits: GridSizeUnits,
): { width: number, height: number } {
  const screen = viewport()
  const v = {
    width: screen.width,
    height: screen.height - TOP_MARGE,
  }
  const { rows, columns } = gridSizeUnits
  return {
    width: ((v.width / columns) << 0) - BORDER_WIDTH * 2,
    height: ((v.height / rows) << 0) - BORDER_WIDTH * 2,
  }
}
