export default class MutateToolbarMobile {
  private static readonly instances: MutateToolbarMobile[] = []
  public onClickRemember = (): void => undefined
  public onClickMutate = (): void => undefined
  public onClickEdit = (): void => undefined
  private readonly element: HTMLDivElement
  private readonly saveButton: HTMLButtonElement
  private readonly mutateButton: HTMLButtonElement
  private readonly buttonGroup: HTMLDivElement
  private isVisible = false

  constructor(parentElement: HTMLElement) {
    this.element = document.createElement('div')
    this.element.className = 'toolbar'

    this.buttonGroup = document.createElement('div')
    this.element.className = 'button-group'
    this.element.append(this.buttonGroup)

    parentElement.addEventListener('touchstart', (e) => {
      e.stopPropagation()
      setTimeout(() => {
        if (!this.isVisible) {
          MutateToolbarMobile.hideAll()
          this.visible = true
        } else {
          MutateToolbarMobile.hideAll()
          this.onClickMutate()
        }
      }, 200)

      return false
    })

    this.saveButton = document.createElement('button')
    this.saveButton.className = 'button'
    this.saveButton.innerHTML = 'to garden'

    this.saveButton.addEventListener('touchstart', (e) => {
      if (this.isVisible) {
        e.stopPropagation()
        MutateToolbarMobile.hideAll()
        this.onClickRemember()
      }
    })

    this.mutateButton = document.createElement('button')
    this.mutateButton.className = 'button'
    this.mutateButton.innerHTML = 'mutate'

    this.mutateButton.addEventListener('touchstart', (e) => {
      if (this.isVisible) {
        e.stopPropagation()
        MutateToolbarMobile.hideAll()
        setTimeout(() => {
          this.onClickMutate()
        }, 500)
      }
    })

    this.buttonGroup.appendChild(this.mutateButton)
    this.buttonGroup.appendChild(this.saveButton)
    this.element.className = 'toolbar'
    parentElement.appendChild(this.element)
    MutateToolbarMobile.instances.push(this)
    this.update()
  }

  set visible(value: boolean) {
    this.isVisible = value
    this.update()
  }

  static hideAll(): void {
    MutateToolbarMobile.instances.forEach((instance: MutateToolbarMobile) => {
      instance.visible = false
    })
  }

  static set allEnabled(value: boolean) {} // eslint-disable-line
  update(): void {
    this.element.style.opacity = this.isVisible ? '1' : '0'
  }
}
