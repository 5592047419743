import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import Provider from './ui/Provider'

function handleResize() {
  let throttleId: NodeJS.Timeout | null = null
  return () => {
    if (throttleId) {
      clearTimeout(throttleId)
    }
    throttleId = setTimeout(() => {
      window.location.href = window.location.href // eslint-disable-line
    }, 250)
  }
}

window.addEventListener('resize', handleResize())

ReactDOM.render(<Provider />, document.getElementById('root'))
