import React, { Component } from 'react'
import Store from '../store'
import App from './App'

const store = new Store()

interface Props {} // eslint-disable-line

interface State {
  rendered: number
}

export default class Provider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      rendered: 0,
    }
  }

  componentDidMount(): void {
    store.onChange = () => {
      this.setState({ rendered: this.state.rendered + 1 })
    }
  }

  render(): JSX.Element {
    return <App store={store} favs={store.favs} />
  }
}
