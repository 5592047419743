import Network from './cgp/Network'

type AppMode = 'mutant.garden' | 'autobreeder' | 'editor' | 'network'

const DEFAULT_MODE: AppMode = 'mutant.garden'

declare global {
  interface Window {
    config: {
      appMode: AppMode
    }
    data: {
      route: string
      mutant: {
        cgp: Network
      }
    }
  }
}

export const appMode: AppMode = (
  window.config.appMode ?? DEFAULT_MODE
).toLowerCase() as AppMode

export const isInteractive = appMode === 'mutant.garden' || appMode === 'editor'
