import React, { Component } from 'react'
import sha1 from 'sha1'
import SVG from 'svg.js'
import Parser from '../../parser/Parser'
import { Viewport } from '../../utils'
import { GridSizeUnits } from '../../graphics/gridSize'
import Network from '../../cgp/Network'

interface Props {
  drawing: Network
  gridSizeUnits: GridSizeUnits
}

interface State {
  width: number
  height: number
  downloadUrl: string | undefined
  minSplit: number
  maxSplit: number
}

const initialState: State = {
  width: 475 - 27,
  height: 645 - 27,
  downloadUrl: undefined,
  minSplit: 1,
  maxSplit: 4,
}

export default class EditScreen extends Component<Props, State> {
  private containerElement: HTMLDivElement | undefined
  private svg: SVG.Doc | undefined

  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  async draw(): Promise<void> {
    if (this.containerElement === undefined || this.svg === undefined) return
    const { drawing } = this.props
    this.svg.clear()
    const drawingSize: Viewport = {
      width: this.state.width,
      height: this.state.height,
    }

    this.svg.size(drawingSize.width, drawingSize.height)
    const parser = new Parser(drawing, this.svg, drawingSize, {
      minSplit: this.state.minSplit,
      maxSplit: this.state.maxSplit,
    })
    await parser.draw()
  }

  initEditScreen = async (containerElement: HTMLDivElement): Promise<void> => {
    if (containerElement === undefined || this.containerElement !== undefined) {
      return
    }
    this.containerElement = containerElement
    this.svg = SVG(this.containerElement)
    await this.draw()
  }

  drawVoid(): void {
    const promise = this.draw() // eslint-disable-line @typescript-eslint/no-unused-vars
  }

  handleChangeWidth = (e: any): void => {
    console.log(e.target.value)
    this.setState({ width: e.target.value }, () => {
      this.drawVoid()
    })
  }

  handleChangeHeight = (e: any): void => {
    this.setState({ height: e.target.value }, () => {
      this.drawVoid()
    })
  }

  handleChangeMinSplit = (e: any): void => {
    const newValue = e.target.value
    if (newValue > this.state.maxSplit) return
    this.setState({ minSplit: newValue }, () => {
      this.drawVoid()
    })
  }

  handleChangeMaxSplit = (e: any): void => {
    const newValue = e.target.value
    if (newValue < this.state.minSplit) return
    this.setState({ maxSplit: newValue }, () => {
      this.drawVoid()
    })
  }

  save = (): void => {
    if (!this.svg) return
    const svg = this.svg.node
    const serializer = new XMLSerializer()
    let source = serializer.serializeToString(svg)
    if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"',
      )
    }
    if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"',
      )
    }

    source = '<?xml version="1.0" standalone="no"?>\r\n' + source

    const url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source)

    this.setState({ downloadUrl: url }, () => {
      document.getElementById('download-button')?.click()
    })
  }

  render(): JSX.Element {
    return (
      <div className='edit-screen'>
        <div ref={this.initEditScreen} className='edit-screen-drawing' />
        <div className='edit-screen-controls'>
          <input
            value={this.state.width}
            type='range'
            min='0'
            max='1000'
            onChange={this.handleChangeWidth}
          />
          <input
            value={this.state.height}
            type='range'
            min='0'
            max='1000'
            onChange={this.handleChangeHeight}
          />
          <hr />
          <input
            value={this.state.minSplit}
            type='range'
            min='0'
            max='12'
            onChange={this.handleChangeMinSplit}
          />
          <input
            value={this.state.maxSplit}
            type='range'
            min='0'
            max='12'
            onChange={this.handleChangeMaxSplit}
          />
          <hr />
          <button type='button' onClick={this.save}>
            save
          </button>
          {this.state.downloadUrl && (
            <a
              id='download-button'
              style={{ display: 'none' }}
              href={this.state.downloadUrl}
              target='_blank'
              download={`mutant-garden-${sha1(this.state.downloadUrl)}.svg`}
            >
              download svg
            </a>
          )}
        </div>
      </div>
    )
  }
}
