import React, { Component } from 'react'

interface Props {
  onClose: () => void
  perma: string | null
  image: string | null
}

export default class Perma extends Component<Props> {
  private element: HTMLDivElement | null = null

  setElement = (element: HTMLDivElement): void => {
    if (element !== null && this.element === null) {
      this.element = element
      setTimeout(() => {
        if (this.element === null) return
        this.element.style.opacity = '1'
      }, 0)
    }
  }

  hide(): void {
    if (this.element === null) return
    this.element.style.opacity = '0'
    setTimeout(() => {
      this.props.onClose()
    }, 260)
  }

  render(): JSX.Element {
    const { perma, image } = this.props

    return (
      <div className='modal' ref={this.setElement}>
        <div className='modal-background-toggle' onClick={() => this.hide()} />
        <div className='modal-content perma-modal-content'>
          {perma === null
            ? (
            <span>generating permalink&hellip;</span>
              )
            : (
            <>
              {image && <img src={image} className='perma-image' />}
              <a href={perma} target='_blank'>
                {perma}
              </a>
            </>
              )}
        </div>
      </div>
    )
  }
}
