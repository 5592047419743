import React, { Component } from 'react'

interface Props {
  onClose: () => void
}

export default class About extends Component<Props> {
  private element: HTMLDivElement | null = null

  setElement = (element: HTMLDivElement): void => {
    if (element !== null && this.element === null) {
      this.element = element
      setTimeout(() => {
        if (this.element === null) return
        this.element.style.opacity = '1'
      }, 0)
    }
  }

  hide(): void {
    if (this.element === null) return
    this.element.style.opacity = '0'
    setTimeout(() => {
      this.props.onClose()
    }, 260)
  }

  render(): JSX.Element {
    return (
      <div className='modal' ref={this.setElement}>
        <div className='modal-background-toggle' onClick={() => this.hide()} />
        <div className='modal-content about-modal-content'>
          <div className='modal-content-scroll'>
            <h1>Mutant Garden</h1>
            <p>By Harm van den Dorpel, 2020</p>
            <p>
              This artwork uses an algorithm called &apos;Cartesian genetic
              programming&apos;, as proposed by Julian F. Miller and Peter
              Thomson, approximately twenty years ago. It is called ‘Cartesian’
              because it represents a program using a two-dimensional grid of
              nodes. By its nature, its genetic encoding contains many redundant
              genes, which are well known to assist in effective evolutionary
              development.
            </p>
            <p>
              In Mutant Garden, clicking one rectangular &apos;mutant&apos; will
              cause its siblings to be replaced with newly mutated offspring.
              This breeding strategy requires only one parent to be selected
              because the algorithm is the most effective when mutation, rather
              than cross-over, is applied.
            </p>
            <p>
              As many people nowadays equate articial intelligence with neural
              networks, the artist wants to highlight other moments in this
              recent history of computation, approaching it as algorithmic
              archeology. Each new innovation in this lineage aimed to improve
              or render its predecessor obsolete, yet would simultaneously stand
              on its shoulders.
            </p>
            <p>
              In hindsight, this work can be regarded the fifth installment of
              the artist&apos;s chronological inquiry into the history of
              computation, preceded by{' '}
              <a
                href='https://harmvandendorpel.com/live-and-let-live'
                target='_blank'
                rel='noopener noreferrer'
              >
                Markov&apos;s Window
              </a>{' '}
              (2004),{' '}
              <a
                href='https://harmvandendorpel.com/death-imitates-language'
                target='_blank'
                rel='noopener noreferrer'
              >
                Death Imitates Language
              </a>{' '}
              (2015),{' '}
              <a
                href='https://harmvandendorpel.com/algues-artificielles'
                rel='noopener noreferrer'
                target='_blank'
              >
                Algues Artificielles
              </a>{' '}
              (2017), and{' '}
              <a
                href='https://harmvandendorpel.com/nested-exchange'
                target='_blank'
                rel='noopener noreferrer'
              >
                Nested Exchange
              </a>{' '}
              (2018).
            </p>
            <p>
              For more information about this project and unique edition prints
              see the{' '}
              <a
                rel='noopener noreferrer'
                href='https://harmvandendorpel.com/structures-of-redundancy'
                target='_blank'
              >
                artist website
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    )
  }
}
