import { appMode } from '../../settings'
import { isTouchDevice } from '../../utils'

export default class FavToolbar {
  private static readonly instances: FavToolbar[] = []
  public onClickRemove = (): void => undefined
  public onClickMutate = (): void => undefined
  public onClickAdopt = (): void => undefined
  public onClickEdit = (): void => undefined
  public readonly parentElement: HTMLDivElement
  private readonly mutateButton: HTMLButtonElement
  private readonly adoptButton: HTMLButtonElement
  private readonly editButton: HTMLButtonElement | undefined
  private readonly removeButton: HTMLButtonElement
  private isEnabled = true
  private isVisible = false

  constructor(parentElement: HTMLElement) {
    this.parentElement = document.createElement('div')
    this.parentElement.className = 'toolbar'

    this.mutateButton = document.createElement('button')
    this.mutateButton.type = 'button'
    this.mutateButton.className = 'button'
    this.mutateButton.innerHTML = 'fork'
    this.mutateButton.addEventListener('mousedown', (e) => {
      this.onClickMutate()
      e.stopPropagation()
    })
    this.parentElement.appendChild(this.mutateButton)

    this.adoptButton = document.createElement('button')
    this.adoptButton.className = 'button'
    this.adoptButton.innerHTML = 'perma'
    this.adoptButton.addEventListener('mousedown', (e) => {
      e.stopPropagation()
      this.onClickAdopt()
    })
    this.parentElement.appendChild(this.adoptButton)

    if (appMode === 'editor') {
      this.editButton = document.createElement('button')
      this.editButton.className = 'button'
      this.editButton.innerHTML = 'edit'
      this.editButton.addEventListener('mousedown', (e) => {
        e.stopPropagation()
        this.onClickEdit()
      })
      this.parentElement.appendChild(this.editButton)
    }

    this.removeButton = document.createElement('button')
    this.removeButton.className = 'button'
    this.removeButton.innerHTML = 'free'
    this.removeButton.type = 'button'
    this.parentElement.appendChild(this.removeButton)

    this.removeButton.addEventListener('mousedown', (e) => {
      e.stopPropagation()
      this.onClickRemove()
    })

    parentElement.appendChild(this.parentElement)
    FavToolbar.instances.push(this)
  }

  set visible(value: boolean) {
    this.isVisible = value
    this.update()
  }

  set enabled(value: boolean) {
    this.isEnabled = value
    this.update()
  }

  static set allEnabled(value: boolean) {
    FavToolbar.instances.forEach((instance: FavToolbar) => {
      instance.enabled = value
    })
  }

  update(): void {
    this.parentElement.style.opacity =
      (this.isEnabled && this.isVisible) || isTouchDevice ? '1' : '0'
  }
}
