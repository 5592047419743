import { sample, random } from 'lodash'
import { Palette } from '../graphics/color.types'
import { initArray } from '../utils'
import { randomColorArray } from '../graphics/color'
import * as Commands from '../constants/commands'
import { List, CommandId, weightedCommands } from './commands'
import Node from './Node'
import {
  CGPVariableAddress,
  NodeAddress,
  VariableValue,
  Memory,
  Dimensions,
} from './types'
import { MEMORY_VALUE_MAX } from './Network'

export const TERMINATOR_NODE_ADDRESS = -1

const NEXT_LAYER_JUMP_ONLY = true

export const randomVariableAddress = (
  dimensions: Dimensions,
): CGPVariableAddress => random(dimensions.memory - 1)

export const initRandomPalette = (dimensions: Dimensions): Palette =>
  initArray(dimensions.colors, () => randomColorArray(dimensions))

export const getRandomNodeIndex = (
  dimensions: Dimensions,
  layer: number,
): number => {
  const firstNode = (layer + 1) * dimensions.layerSize
  const j = NEXT_LAYER_JUMP_ONLY
    ? dimensions.layerSize
    : dimensions.layerSize * dimensions.layers

  const result = Math.floor(Math.random() * j) + firstNode
  if (NEXT_LAYER_JUMP_ONLY && result - firstNode > dimensions.layerSize) {
    console.error('exceeds!', firstNode, result)
  }
  return result
}

export function createRandomNode(
  dimensions: Dimensions,
  index: number,
  layer: number,
): Node {
  const last = layer === dimensions.layers - 1
  const commands = weightedCommands()
  const randomNext = (): NodeAddress =>
    last ? TERMINATOR_NODE_ADDRESS : getRandomNodeIndex(dimensions, layer)
  const command = last ? Commands.TERMINATE : sample(commands) /// weightedCommands)
  if (command === undefined) throw new Error('this should not happen')
  return new Node(
    index,
    command,
    [randomNext(), randomNext(), randomNext()],
    [
      randomVariableAddress(dimensions),
      randomVariableAddress(dimensions),
      randomVariableAddress(dimensions),
    ],
  )
}

export function between(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min)
}

export function createRandomMemory(dimensions: Dimensions): VariableValue[] {
  const result = []
  for (let i = 0; i < dimensions.memory; i++) {
    result[i] = random(MEMORY_VALUE_MAX)
  }
  return result
}

export function mutateStep(
  dimensions: Dimensions,
  nodes: Node[],
  commands: List,
  memory: Memory,
  palette: Palette,
): void {
  const touchedNodes = nodes.filter(
    (node) => node.touched && node.command !== Commands.TERMINATE,
  )
  const useNodes = touchedNodes.length === 0 ? nodes : touchedNodes
  const nodeIndex = Math.round(Math.random() * (useNodes.length - 1))
  const node = useNodes[nodeIndex]
  const mutationKind = random(6)

  switch (mutationKind) {
    case 0:
    case 1:
      node.command = sample(
        commands.filter((command) => command !== node.command),
      ) as CommandId
      break

    case 2:
    case 3: {
      const newNodeAddess = getRandomNodeIndex(
        dimensions,
        Math.floor(node.index / dimensions.layerSize),
      )
      node.next[random(2)] = newNodeAddess
      break
    }

    case 4:
      node.variables[random(2)] = randomVariableAddress(dimensions)
      break

    case 5: {
      const address = node.variables[random(2)]
      memory[address] = random(MEMORY_VALUE_MAX)
      break
    }

    case 6: {
      const paletteIndex = random(palette.length - 1)
      palette[paletteIndex][random(2)] = random(dimensions.memory)
      break
    }

    default:
      alert('unknown mutation kind')
  }
}
