import SVG from 'svg.js'
import { Dimensions } from '../cgp/types'
import { Channel, ColorPointer, Color, GradientType } from './color.types'

export const CHANNEL_ALPHA = 3

export const randomChannelAddress = (dimensions: Dimensions): Channel =>
  (Math.random() * (dimensions.memory - 1)) << 0

export const randomColorArray = (dimensions: Dimensions): ColorPointer => [
  randomChannelAddress(dimensions),
  randomChannelAddress(dimensions),
  randomChannelAddress(dimensions),
  randomChannelAddress(dimensions),
]

export const dim = (color: Color, d: number): Color => [
  (color[0] * d) << 0,
  (color[1] * d) << 0,
  (color[2] * d) << 0,
  (color[3] / 255) * 0.85,
]

const RED_MUL = 1
const GREEN_MUL = 0.9
const BLUE_MUL = 1

export const toCSSRGB = (rgbArray: Color): string =>
  `rgb(${(rgbArray[0] * RED_MUL) << 0},${(rgbArray[1] * GREEN_MUL) << 0},${
    (rgbArray[2] * BLUE_MUL) << 0
  })` // eslint-disable-line

export const toCSSRGBFull = (rgbArray: Color): string =>
  `rgb(${rgbArray[0] << 0},${rgbArray[1] << 0},${rgbArray[2] << 0})` // eslint-disable-line

export const toCSSRGBA = (rgbArray: Color): string =>
  `rgba(${(rgbArray[0] * RED_MUL) << 0},${(rgbArray[1] * GREEN_MUL) << 0},${
    (rgbArray[2] * BLUE_MUL) << 0
  }, ${rgbArray[3] / 255})` // eslint-disable-line

export function dimmedGradient(
  color: Color,
  svg: SVG.Doc,
  direction: boolean,
  gradientDimFactor: number,
  gradientType: GradientType,
): SVG.Gradient | string {
  // fixme: this might not be correct
  // eslint-disable-line @typescript-eslint/no-explicit-any
  // eslint-disable-line
  const dimmedColor: Color = dim(color, gradientDimFactor)
  const result = svg.gradient(gradientType, function (stop: SVG.Gradient) {
    stop.at({
      offset: 0,
      color: direction ? toCSSRGB(dimmedColor) : toCSSRGB(color),
      opacity: 1, // (direction ? dimmedColor[3] / 255 : color[3] / 255),
    })
    stop.at({
      offset: 1,
      color: !direction ? toCSSRGB(dimmedColor) : toCSSRGB(color),
      opacity: 1, // (!direction ? dimmedColor[3] / 255 : color[3] / 255),
    })
  })

  if (gradientType === 'radial') {
    return result.fill()
  }

  return result.from(0, 0).to(0, 1)
}
