import { CommandId } from './commands'
import { VariableAddresses, NextNodes } from './types'

export default class Node {
  public command: CommandId
  public next: NextNodes
  public variables: VariableAddresses
  public touched = false
  public index: number
  constructor(
    index: number,
    command: CommandId,
    next: NextNodes,
    variables: VariableAddresses,
  ) {
    this.index = index
    this.command = command
    this.next = next
    this.variables = variables
  }
}
