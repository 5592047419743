import { svgAsPngUri } from 'save-svg-as-png'
import Network from '../cgp/Network'
import ImageFlipBuffer from './ImageFlipBuffer'

interface FitnessResult {
  largestComplexity: number
  largestIndex: number
}

export async function fitnessFunction(
  variationsBuffers: ImageFlipBuffer[],
): Promise<FitnessResult> {
  const promises: Array<Promise<string>> = variationsBuffers.map((_, index) =>
    asPng(variationsBuffers[index]),
  )

  const result = await Promise.all(promises)
  let largestIndex = 0
  let largestComplexity = 0

  for (let i = 0; i < result.length; i++) {
    const complexity = result[i].length

    if (complexity > largestComplexity) {
      largestIndex = i
      largestComplexity = complexity
    }
  }

  return { largestComplexity, largestIndex }
}

const asPng = (buffer: ImageFlipBuffer) => svgAsPngUri(buffer.visibleSVG.node)

export function findSuitableRandomDrawing(): Network {
  const hiddenParentElement = document.createElement('div')
  window.document.body.appendChild(hiddenParentElement)
  if (hiddenParentElement === null) {
    throw new Error('Cannot find hidden DOM element')
  }
  const newRandomDrawing = new Network()
  return newRandomDrawing
}
