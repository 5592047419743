import React, { Component } from 'react'
import SVG from 'svg.js'
import { Viewport } from '../utils'
import Network from '../cgp/Network'
import { appMode, isInteractive } from '../settings'
import { findSuitableRandomDrawing } from '../graphics/fitness'
import {
  calculateDrawingsize,
  calculateGridSizeUnits,
} from '../graphics/gridSize'
import Store from '../store'
import MainToolbar, { Route } from './toolbars/MainToolbar'
import About from './About'
import Perma from './Perma'
import FavsScreen from './screens/FavsScreen'
import MutatorScreen from './screens/MutatorScreen'
import EditScreen from './screens/EditScreen'
import TracerScreen from './screens/TracerScreen'

// const API_URL = 'http://localhost:3000'
const API_URL = 'https://mutant.garden'

interface State {
  drawing: Network
  route: Route
  mutatorScreenKey: number
  aboutVisible: boolean
  permaVisible: boolean
  perma: string | null
  permaImage: string | null
}

interface Props {
  store: Store
  favs: Network[]
}

const initialState: State = {
  drawing: new Network(),
  route: appMode === 'network' ? 'network' : 'lab',
  mutatorScreenKey: 1,
  aboutVisible: false,
  permaVisible: false,
  perma: null,
  permaImage: null,
}

export default class App extends Component<Props, State> {
  private readonly gridSizeUnits = calculateGridSizeUnits()
  private readonly drawingSize: Viewport = calculateDrawingsize(
    this.gridSizeUnits,
  )

  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  async componentDidMount(): Promise<void> {
    const drawing = await this.determineDrawing()
    this.setState({ drawing })
  }

  async determineDrawing(): Promise<Network> {
    // const { currentDrawing } = store
    const drawing: Network = // currentDrawing === null
      // ?
      findSuitableRandomDrawing() // this.drawingSize)
    // : currentDrawing
    return drawing
  }

  handleClickRemember = (drawing: Network): void => {
    this.setState({ route: 'garden' })
    this.props.store.addDrawing(drawing)
  }

  takeFromGardenToMutate = (index: number): void => {
    const { favs } = this.props
    console.log(favs[index])
    this.setState({
      route: 'lab',
      drawing: favs[index],
      mutatorScreenKey: this.state.mutatorScreenKey + 1,
    })
  }

  async seed(): Promise<void> {
    this.setState({
      drawing: await this.determineDrawing(),
      mutatorScreenKey: this.state.mutatorScreenKey + 1,
    })
  }

  closeAbout = (): void => {
    this.setState({ aboutVisible: false })
  }

  closePerma = (): void => {
    this.setState({ permaVisible: false })
  }

  handleAdopt = async (index: number, svg: SVG.Doc): Promise<void> => {
    if (svg === null) throw new Error('svg is empty')
    const width = Number(svg.node.getAttribute('width'))
    const height = Number(svg.node.getAttribute('height'))
    const aspectRatio = width / height
    const WIDTH = 1400
    const svgCopy: SVGElement = svg.node.cloneNode(true) as SVGElement
    svgCopy.setAttribute(
      'viewBox',
      `0 0 ${width.toString()} ${height.toString()}`,
    )
    svgCopy.setAttribute('width', WIDTH.toString())
    svgCopy.setAttribute('height', (WIDTH / aspectRatio).toString())

    const data = {
      cgp: this.props.favs[index],
      svg: svgCopy.outerHTML,
      aspectRatio,
      width,
      height,
    }

    const body = JSON.stringify(data)

    this.setState({
      permaVisible: true,
      perma: null,
      permaImage: null,
    })

    const response = await fetch(`${API_URL}/remember`, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const { perma, png: permaImage } = await response.json()

    this.setState({
      perma,
      permaImage,
    })
  }

  handleEdit = (drawing: Network): void => {
    this.setState({ route: 'edit', drawing })
  }

  render(): JSX.Element {
    const { store, favs } = this.props
    const { drawing, route, mutatorScreenKey } = this.state

    const globalData = window.data
    const finalDrawing: Network =
      globalData.route === 'mutant' ? window.data.mutant.cgp : drawing

    if (finalDrawing === undefined) {
      return <div>loading...</div>
    }

    if (route === 'network') {
      return <TracerScreen />
    }

    return (
      <>
        <MutatorScreen
          visible={route === 'lab'}
          key={mutatorScreenKey}
          gridSizeUnits={this.gridSizeUnits}
          drawingSize={this.drawingSize}
          drawing={finalDrawing}
          onClickRemember={this.handleClickRemember}
          onClickEdit={this.handleEdit}
        />

        {isInteractive && (
          <FavsScreen
            visible={route === 'garden'}
            drawings={favs}
            gridSizeUnits={this.gridSizeUnits}
            drawingSize={this.drawingSize}
            onClickDrawing={this.takeFromGardenToMutate}
            onClickRemove={(index: number) => store.removeDrawing(index)}
            onClickMutate={this.takeFromGardenToMutate}
            onClickAdopt={this.handleAdopt}
            onClickEdit={this.handleEdit}
          />
        )}
        {appMode === 'editor' && route === 'edit' && (
          <EditScreen gridSizeUnits={this.gridSizeUnits} drawing={drawing} />
        )}
        {isInteractive && (
          <MainToolbar
            route={this.state.route}
            onSeed={() => this.seed()}
            onClickAbout={() => {
              this.setState({ aboutVisible: true })
            }}
            onNavigate={(route: Route) => {
              this.setState({ route })
            }}
          />
        )}
        {this.state.permaVisible && (
          <Perma
            image={this.state.permaImage}
            perma={this.state.perma}
            onClose={this.closePerma}
          />
        )}
        {this.state.aboutVisible && <About onClose={this.closeAbout} />}
      </>
    )
  }
}
